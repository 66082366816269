import CustomInstancesBlockText from "../../reusable/render/customInstancesBlockText"
import BlockText from "../../reusable/render/BlockText"
import React, {useContext} from "react"
import OrganizationContext from "../../../context/OrganizationContext"
import {useRouter} from "next/router"
import DataContext from "../../../context/DataContext"
import {getOrganizationDataFromStrapi} from "../../../utility/strapi/helpers"

type Props = {
	hideBikes?: boolean;
	is404Page?: boolean;
    contentData: any
    hasDescription?: boolean
};

const HomeCatBoxesHeader: React.FC<Props> = ({hideBikes, is404Page, contentData, hasDescription}) => {
	const router = useRouter()
	const {locale} = router
	const content = contentData[locale]
	const {organizationSlug} = useContext(OrganizationContext)
	const {pageProps} = useContext(DataContext)
	const organizations = pageProps?.strapiContent?.data?.organizations

	const organizationData = getOrganizationDataFromStrapi(organizations, organizationSlug)

	return (
		<div className="d-flex flex-column container-md justify-content-center align-items-center">
			{hideBikes ? null : (process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug) ? (
				<div className="grid-2">
					<CustomInstancesBlockText
						instanceShowHPText={Boolean((process.env.NEXT_PUBLIC_CUSTOM_INSTANCE && organizationSlug) && organizationData?.home)}
					/>
					{content.map(({fields}, idx) => {
						return (
							<BlockText
								key={idx}
								fields={fields}
								textClassname={"mt-0 mb-2 mb-md-4 text-md-center"}
								hasDescription={hasDescription}
							/>
						)
					})}
				</div>) : is404Page ? null : (
				<div className="col-12 mt-5 mt-md-0 col-lg-8 text-md-center">
					{content.map(({fields}, idx) => {
						return (
							<BlockText
								key={idx}
								fields={fields}
								textClassname={"mt-0 mb-2 mb-md-4"}
								hasDescription={hasDescription}
							/>
						)
					})}
				</div>
			)}
		</div>
	)
}

export default HomeCatBoxesHeader
